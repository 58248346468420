import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { API_URL_TENDERS, API_BASE_URL } from "../configuration/Configuration";
import LanePricing from "./advancing/LanePrices";
import Checks from "./advancing/Checks";
import { Divider } from "@mui/material";
import axios from "axios";
import Map from "./Map"
const AdvanceTender = () => {
  const [userType, setUserType] = useState({
    isAdmin: false,
    isRegular: false,
    isStaff: false,
    isChecks: false,
  });
  const [user, setUser] = useState();

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true);

      setIsAuth(true);
      let tempUser = localStorage.getItem("user");

      axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`).then((res) => {
        const isAdmin = res.data.is_admin;
        const isStaff = res.data.is_staff;
        const isRegular = res.data.is_regular;
        let isChecks = false;
        if (localStorage.getItem("usertype") === "checks") {
          isChecks = true;
        }
        setUserType({ isAdmin, isStaff, isRegular, isChecks });
      });
      setUser(localStorage.getItem("user"));
    }
  }, [isAuth]);




  return (
    <Box>
      <Map></Map>
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item xs={0.5}></Grid>
        {/*
        <Grid item xs={11.5}>
        {(userType !== "checks") && (
          <Typography variant="h5" align="left">Manage your Tenders. Double Click on a Tender to View and Enter Lane Information.</Typography>
          )}
          {(userType === "checks") && (
            <Typography variant="h5" align="left">Manage your Tenders. Double Click on a Tender to Enter the necessary Checks.</Typography>
            )}
            </Grid>
          */}
        <Grid item xs={12}></Grid>
        <Divider style={{ width: "100%" }} flexitem="true" />
        <Grid item xs={12}></Grid>
      </Grid>
      {isAuth && userType.isChecks && (
        <Checks user={user} tender_input={API_URL_TENDERS}></Checks>
      )}
      {isAuth && (userType.isRegular || userType.isStaff) && (
        <LanePricing
          price_type={"regional"}
          check_type={"regional_pricing"}
          pricing_label={"Regional Prices Completed"}
          user={user}
          tender_input={API_URL_TENDERS}
        ></LanePricing>
      )}
      {userType.isAdmin && (
        <LanePricing
          price_type={"final"}
          price_type_r2={"round2"}
          price_type_r3={"round3"}
          check_type={"final_pricing"}
          pricing_label={"Final Prices Completed"}
          user={user}
          tender_input={API_URL_TENDERS}
        ></LanePricing>
      )}
    </Box>
  );
};
export default AdvanceTender;
