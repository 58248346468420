import React from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { AgGridReact } from 'ag-grid-react';
import { API_URL_TENDERS, API_URL_STATE_UPDATE, dateFormatter, filterOptionsDate, dateSort, filterOptions, auditFormatter } from "../../configuration/Configuration"
import { isPosted } from '../../configuration/Utils';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TableControls from "../../integrations/core/table/TableControls";
import {FullScreen, useFullScreenHandle} from "react-full-screen";

const formData = {};
let tenderColumnsDefinition;

const Checks = (props) => {
  window.addEventListener("beforeunload", () => { setTender([]); });

  const { key } = useLocation();
  useEffect(() => {
    handleUnload();
    loadOptions();
  }, [key]);

  // has tender ID been selected state
  const [tenderState, setTenderStatus] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  // states from BE
  const [legalCheck, setLegalCheck] = React.useState();
  const [financeCheck, setFinanceCheck] = React.useState();
  const [QSUCheck, setQSUCheck] = React.useState();

  //  adjusted states
  const [changesDone, setChangesDone] = React.useState(false);
  const [legalCheckChange, setLegalCheckChange] = React.useState();
  const [financeCheckChange, setFinanceCheckChange] = React.useState();
  const [QSUCheckChange, setQSUCheckChange] = React.useState();
  const [fileDirectory, setFileDirectory] = React.useState();

  const handleChangeLegal = (event) => {
    setLegalCheckChange(event.target.checked);
    setChangesDone(!changesDone);
  };

  const handleChangeFinance = (event) => {
    setFinanceCheckChange(event.target.checked);
    setChangesDone(!changesDone);
  };

  const handleChangeQSU = (event) => {
    setQSUCheckChange(event.target.checked);
    setChangesDone(!changesDone);
  };

  const [tenders, setTender] = React.useState("");

  const getTenderData = (params) => {
    (async () => {
      let tenderID = params.data.id;
      setTender(tenderID);
      const url_api = API_URL_TENDERS + tenderID
      const { data } = await axios.get(url_api);
      setLegalCheck(data[0].legal_check);
      setFinanceCheck(data[0].finance_check);
      setQSUCheck(data[0].qsu_check);
      setFileDirectory(data[0].link);
    })();
    setTenderStatus(true);
  };

  let changesBackend = { legal: null, finance: null, qsu: null }

  const postChecks = async () => {
    changesBackend.legal = legalCheckChange;
    changesBackend.finance = financeCheckChange;
    changesBackend.qsu = QSUCheckChange;
    formData["changesBackend"] = changesBackend;
    formData["tenderID"] = tenders;
    console.log(formData)
    await axios.post(API_URL_STATE_UPDATE, formData).then(res => console.log("Tender State Update posted", res.data));
    setChangesDone(false);
    setTenderStatus(false);
    setTender();
    setLegalCheckChange();
    setFinanceCheckChange();
    setQSUCheckChange();
    loadOptions();
    // window.location.reload();
  };

  // reset states before unload
  const handleUnload = () => {
    setTenderStatus(false);
    setChangesDone(false);
    setLegalCheck();
    setFinanceCheck();
    setQSUCheck();
    setLegalCheckChange();
    setFinanceCheckChange();
    setQSUCheckChange();
  };

  let fullScreenHandle = useFullScreenHandle();
  let fullScreenHandle2 = useFullScreenHandle();
  const gridRef1 = React.useRef();
  const gridRef2 = React.useRef();
  const gridStyle = React.useMemo(() => ({ height: 350, width: '100%' }), []);

  // retrieve menu items out of endpoint
  // const [options, setOptions] = React.useState([]);


  const [tenderData0, setTenderData0] = React.useState("");
  const [tenderData1, setTenderData1] = React.useState("");

  const loadOptions = () => {

    tenderColumnsDefinition = [
      {
        field: 'company', headerName: 'Company Name',
        filter: true,
        sortable: true,
      },
      {
        field: 'date_created',
        headerName: 'Creation Date',
        valueFormatter: dateFormatter,
        filter: true,
        sortable: true,
      },];
    switch (props.user[0]) {
      case "f":
        tenderColumnsDefinition.push({
          field: 'finance_check', headerName: 'Financial Check', cellClass: 'booleanType', filter: true, sortable: true,
          filterParams: filterOptions, valueFormatter: auditFormatter,
        },);
        break;
      case "l":
        tenderColumnsDefinition.push({
          field: 'legal_check', headerName: 'Legal Check', cellClass: 'booleanType', filter: true, sortable: true,
          filterParams: filterOptions, valueFormatter: auditFormatter,
        });
        break;
      default:
        tenderColumnsDefinition.push({
          field: 'qsu_check', headerName: 'QSU Check', cellClass: 'booleanType', filter: true, sortable: true,
          filterParams: filterOptions, valueFormatter: auditFormatter,
        });
    }
    tenderColumnsDefinition.push(
      {
        field: 'internalDeadline', headerName: 'Internal Deadline', filter: true, sortable: true,
        comparator: dateSort, filterParams: filterOptionsDate,
      },
    );
    // { field: 'regional_pricing', headerName: 'Regional Pricing', cellClass: 'booleanType' },
    (async () => {
      // const opt = [];
      // let filter = event.target.value;
      const data0 = (await axios.get(props.tender_input + 'filtered/' + props.user[0] + "0Check/" + (props.user).replace("-", ""))).data;
      setTenderData0(data0);
      const data1 = (await axios.get(props.tender_input + 'filtered/' + props.user[0] + "1Check/" + (props.user).replace("-", ""))).data;
      setTenderData1(data1);
      console.log(data1)
    })();
  };


  const [comment, setComment] = React.useState("");

  const rejectTender = async () => {
    let pushData = {}
    let changes = { round2: { value: "Rejected_" + props.user } };
    pushData["changesBackend"] = changes;
    pushData["tenderID"] = tenders;
    pushData["comment"] = comment;
    pushData["user"] = props.user;

    console.log(pushData["changesBackend"]);
    if (window.confirm("do you really want to reject the tender?")) {
      await axios.post(API_URL_STATE_UPDATE, pushData).then(res => console.log("Tender State Update posted", res.data));
      // window.location.reload();
      setTenderStatus(false);
      setTender();
      setLegalCheckChange(false);
      setFinanceCheckChange(false);
      setQSUCheckChange(false);
      loadOptions();
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!isPosted(tenderState) && (
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11.5}>
            <Typography variant="h6" align="left">Tenders with Checks to be Done</Typography>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={12}>
            <FullScreen handle={fullScreenHandle}>
              <TableControls table={gridRef1} fullScreenHandle={fullScreenHandle}/>
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef1} // Ref for accessing Grid's API
                  rowData={tenderData0} // Row Data for Rows
                  columnDefs={tenderColumnsDefinition} // Column Defs for Columns
                  onRowDoubleClicked={getTenderData}
                />
              </div>
            </FullScreen>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11.5}>
            <Typography variant="h6" align="left">Tenders with Completed Checks</Typography>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={12}>
            <FullScreen handle={fullScreenHandle2}>
              <TableControls table={gridRef2} fullScreenHandle={fullScreenHandle2}/>
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef2} // Ref for accessing Grid's API
                  rowData={tenderData1} // Row Data for Rows
                  columnDefs={tenderColumnsDefinition} // Column Defs for Columns
                  onRowDoubleClicked={getTenderData}
                />
              </div>
            </FullScreen>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>)}
      {isPosted(tenderState) && (
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item xs={12}></Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1}>
            <Button variant="outlined" size="large" color="inherit" onClick={handleUnload}>
              Back
            </Button>
          </Grid>
          <Grid item xs={5.5}>
            <TextField id="filled"
              InputLabelProps={{ shrink: true }}
              value={fileDirectory}
              label="Files Stored Under" sx={{ width: '60%' }} disabled />
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              {isPosted(!financeCheck) && (props.user === "finance-department") && (
                <FormControlLabel control={<Checkbox
                  checked={financeCheckChange}
                  onChange={handleChangeFinance}
                />} label="Financial Requirements Fulfilled" />
              )}
              {financeCheck && (props.user === "finance-department") && (
                <FormControlLabel control={<Checkbox defaultChecked onChange={handleChangeFinance} />} label="Financial Requirements Fulfilled" />
              )}
              {/* {isPosted(!financeCheck) && (props.user !== "finance-department") && (
                <FormControlLabel control={<Checkbox disabled />} label="Financial Requirements Fulfilled" />
              )}
              {financeCheck && (props.user !== "finance-department") && (
                <FormControlLabel control={<Checkbox defaultChecked disabled />} label="Financial Requirements Fulfilled" />
              )} */}
              {!legalCheck && (props.user === "legal-department") && (
                <FormControlLabel control={<Checkbox
                  checked={legalCheckChange}
                  onChange={handleChangeLegal}
                />} label="Legal Requirements Fulfilled" />
              )}
              {legalCheck && (props.user === "legal-department") && (
                <FormControlLabel control={<Checkbox defaultChecked onChange={handleChangeLegal} />} label="Legal Requirements Fulfilled" />
              )}
              {/* {isPosted(!legalCheck) && (props.user !== "legal-department") && (
                <FormControlLabel control={<Checkbox disabled />} label="Legal Requirements Fulfilled" />
              )}
              {legalCheck && (props.user !== "legal-department") && (
                <FormControlLabel control={<Checkbox defaultChecked disabled />} label="Legal Requirements Fulfilled" />
              )} */}
              {!QSUCheck && (props.user === "qsu-department") && (
                <FormControlLabel control={<Checkbox
                  checked={QSUCheckChange}
                  onChange={handleChangeQSU}
                />} label="QSU Check Done" />
              )}
              {QSUCheck && (props.user === "qsu-department") && (
                <FormControlLabel control={<Checkbox defaultChecked onChange={handleChangeQSU} />} label="QSU Check Done" />
              )}
              {/* {isPosted(!QSUCheck) && (props.user !== "qsu-department") && (
                <FormControlLabel control={<Checkbox disabled />} label="QSU Check Done" />
              )}
              {QSUCheck && (props.user !== "qsu-department") && (
                <FormControlLabel control={<Checkbox defaultChecked disabled />} label="QSU Check Done" />
              )} */}
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            {(changesDone) && (
              <Button variant="contained" size="large" onClick={postChecks} >
                Post Update
              </Button>
            )}
            {(!changesDone) && (
              <Button variant="contained" size="large" disabled color="inherit" >
                Post Update
              </Button>
            )}
          </Grid>
          <Grid item xs={12}></Grid>
          <Divider style={{ width: '100%' }} flexitem >
            Reject Tender
          </Divider>
          <Grid item xs={1.5}> </Grid>
          <Grid item xs={5.5}>
            <TextField id="outlined-basic" label="Comment" variant="outlined" sx={{ width: '60%' }}
              onChange={handleCommentChange}
              value={comment} />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Button variant="outlined" size="large" onClick={rejectTender} color="inherit" >
              Reject Tender
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
export default Checks;