import React from "react";
import {Chip} from "@mui/material";

const PriorityCellRenderer = ({value, rowData}) => {
  switch (value) {
    case 1:
      //return "low";
      return <Chip label={"low"} sx={{backgroundColor: '#6AE1A1', fontSize: 12, color:"#175a11", fontWeight: 500, height: 19}} disabled/>
    case 3:
      return <Chip label={"high"} sx={{backgroundColor: '#F49791', fontSize: 12, color: "#5a1111", fontWeight: 500, height: 19}} disabled/>
    default:
      return <Chip label={"medium"} sx={{backgroundColor: '#EED198', fontSize: 12, color:"#835D23", fontWeight: 500, height: 19}} disabled/>;
  }
};

export default PriorityCellRenderer;