import { ListItemButton, List } from "@mui/material";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../configuration/Configuration";
import Department from "../department/Department";
const Departments = ({userType}) => {
  const [departments, setDepartments] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [name, setName] = useState("");

  const [selectedDepartment, setSelectedDepartment] = useState();
  useEffect(() => {
    let tempUser = localStorage.getItem("user");
    axios
      .get(`${API_BASE_URL}/api/departments/${tempUser}`)
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleCreate = () => {
    setIsCreating(!isCreating);
  };
  const createDepartment = (e) => {
    e.preventDefault();
    let tempUser = localStorage.getItem("user");

    if (name) {
      axios
        .post(`${API_BASE_URL}/api/departments/${tempUser}`, { name: name })
        .then((res) => {
          setName("");
          setIsCreating(false);
          setDepartments(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const hanldeDepartmentSelect = (id, name) => {
    setIsCreating(false);
    setSelectedDepartment({ id: id, name: name });
  };

  return (
    <>
      <div>
        {departments && <></>}
        <div className="departmentWrapper">
          <div className="departmentList">
            <div className="listWrap">
              <List>
                {userType.isAdmin && (
                <ListItemButton
                  //selected={selectedIndex === 2}
                  onClick={(event) => handleCreate(event)}
                >
                  + Create Department
                </ListItemButton>
                )}
                {departments &&
                  departments.map((el) => {
                    return (
                      <ListItemButton
                        onClick={() => hanldeDepartmentSelect(el.id, el.name)}
                        key={el.id}
                      >
                        {el.name}
                      </ListItemButton>
                    );
                  })}
              </List>
            </div>
          </div>
          <div className="departmentView">
            {isCreating ? (
              <div className="creating">
                <Box
                  textAlign="left"
                  component="form"
                  alignItems="center"
                  display="flex"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    required
                    id="name"
                    size="small"
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                    label="Department Name"
                  />
                  <Button
                    type="submit"
                    onClick={createDepartment}
                    variant="contained"
                    sx={{ marginLeft: "20px" }}
                  >
                    Create
                  </Button>
                </Box>
              </div>
            ) : (
              <>
                {departments?.length > 0 ? (
                  <>
                    {selectedDepartment ? (
                      <Department userType={userType} dep={selectedDepartment} />
                    ) : (
                      <div>Select Department</div>
                    )}
                  </>
                ) : (
                  <div>No departments created</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Departments;
