import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import Typography from "@mui/material/Typography";
import { AgGridReact } from "ag-grid-react";
import Grid from "@mui/material/Grid";
import { isPosted, headerColumns } from "../configuration/Utils";

import {
  API_URL_TENDER_OVERVIEW,
  API_BASE_URL,
  API_URL_LANES_PRICING,
  tenderColumnsDefinitionOverview,
  API_URL_EDIT,
} from "../configuration/Configuration";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import TableControls from "../integrations/core/table/TableControls";

let tenderColumnsDefinitionOverviewEdit;
let formData = {};

const EditTender = () => {
  const [editTenderToggle, setEditTenderToggle] = React.useState(false);

  window.addEventListener("beforeunload", () => {
    tenders = [];
  });
  const [userType, setUser] = React.useState({
    isAdmin: false,
    isRegular: false,
    isStaff: false,
  });
  const [isAuth, setIsAuth] = React.useState(false);
  const { key } = useLocation();

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true);
      getTenderDataOverview();
      tenderColumnsDefinitionOverviewEdit =
        tenderColumnsDefinitionOverview.slice();
      tenderColumnsDefinitionOverviewEdit.forEach((value) => {
        value["editable"] = true;
      });

      let tempUser = localStorage.getItem("user");

      axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`).then((res) => {
        const isAdmin = res.data.is_admin;
        const isStaff = res.data.is_staff;
        const isRegular = res.data.is_regular;
        setUser({ isAdmin, isStaff, isRegular });
      });
    }
    resetState();
  }, [isAuth, key]);

  // retrieve tender overview
  let [tenders, setOverview] = React.useState([]);
  // eslint-disable-next-line
  React.useEffect(() => {
    const opt = [];

    (async () => {
      const { data } = await axios.get(API_URL_TENDER_OVERVIEW);
      data.forEach((value) => {
        opt.push(value);
      });
    })();
    setOverview(opt);
  }, []);

  const getTenderDataOverview = () => {
    (async () => {
      const opt = [];
      const url_api = API_URL_TENDER_OVERVIEW;
      const { data } = await axios.get(url_api);
      data.forEach((value) => {
        opt.push(value);
      });
      setOverview(opt);
    })();
  };

  let fullScreenHandle = useFullScreenHandle();
  const gridRef = React.useRef();
  const gridStyle = React.useMemo(() => ({ height: 540, width: "100%" }), []);

  let [tendersSelectionState, setTenderSelectState] = React.useState(false);
  // eslint-disable-next-line
  let [tendersSelected, setTenderSelected] = React.useState(0);
  const [tenderData, setTenderData] = React.useState([]);
  const [gridAdmin, setGridAdmin] = React.useState([]);
  const getTenderData = (tender) => {
    (async () => {
      const url_api = API_URL_LANES_PRICING + tender + "/admin";
      const { data } = await axios.get(url_api);
      setTenderData(data);

      let laneColumnDefinitionAdmin = await headerColumns(tender, true);
      laneColumnDefinitionAdmin.forEach((value) => {
        value["editable"] = true;
      });
      setGridAdmin(laneColumnDefinitionAdmin);
    })();
  };
  const onTenderSelected = (params) => {
    setTenderSelected(params.data.id);
    getTenderData(params.data.id);
    setTenderSelectState(true);
    setChangesDone(false);
    formData = {};
  };

  const resetState = () => {
    setTenderSelectState(false);
    setChangesDone(false);
    setEditTenderToggle(false);
    formData = {};
  };

  const [editType, setEditType] = React.useState("");
  const valueChange = (props, type) => {
    console.log("TYPE:", type);
    console.log("PROPS:", props);
    console.log("NEW VALUE:", props.newValue);
    console.log("COL:", props.column.colId);
    console.log("DATA:", props.data.id);

    let newData = formData[props.data.id];
    if (!Boolean(newData)) {
      newData = {};
    }
    newData[props.column.colId] = props.newValue;
    formData[props.data.id] = newData;
    setEditType(type);
    setChangesDone(true);
    console.log("FORMDATA:", formData);
  };

  const handleChangeSwitch = (event) => {
    let r = event.target.value === "true";
    setEditTenderToggle(r);
  };

  const [changesDone, setChangesDone] = React.useState(false);
  const postChanges = () => {
    (async () => {
      console.log(formData);
      let api_url = API_URL_EDIT + editType;
      await axios
        .post(api_url, formData)
        .then((res) =>
          console.log("Updated " + editType + "; Response: " + res)
        );
      formData = {};
      getTenderDataOverview();
    })();

    setChangesDone(false);
    setTenderSelectState(false);
  };

  return (
    <div className="App">
      {(userType.isAdmin || userType.isStaff) && (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {/* <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Button variant="outlined" size="large" onClick={getTenderDataOverview} color="inherit" >
            Load Data
          </Button>
        </Grid>
        <Grid item xs={8}></Grid> */}
          <Grid item xs={0.5}></Grid>
          <Grid item xs={11.5}>
            <Typography variant="h6" align="left">
              To edit tender information choose 'Edit Tender' or 'Select Lanes'.
              Double clicking a cell allows to edit its content.
            </Typography>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={12}>
            <FullScreen handle={fullScreenHandle}>
              <TableControls
                table={gridRef}
                fullScreenHandle={fullScreenHandle}
              />
              {isPosted(!tendersSelectionState) &&
                !isPosted(editTenderToggle) && (
                  <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                      ref={gridRef} // Ref for accessing Grid's API
                      rowData={tenders} // Row Data for Rows
                      columnDefs={tenderColumnsDefinitionOverview} // Column Defs for Columns
                      onRowDoubleClicked={onTenderSelected}
                      pagination={true}
                      paginationAutoPageSize={true}
                    />
                  </div>
                )}
              {isPosted(!tendersSelectionState) &&
                isPosted(editTenderToggle) && (
                  <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                      ref={gridRef} // Ref for accessing Grid's API
                      rowData={tenders} // Row Data for Rows
                      columnDefs={tenderColumnsDefinitionOverviewEdit} // Column Defs for Columns
                      onCellValueChanged={(e) => {
                        valueChange(e, "tender");
                      }}
                      pagination={true}
                      paginationAutoPageSize={true}
                    />
                  </div>
                )}
              {isPosted(tendersSelectionState) && (
                <div style={gridStyle} className="ag-theme-alpine">
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={tenderData.rows} // Row Data for Rows
                    columnDefs={gridAdmin} // Column Defs for Columns
                    onCellValueChanged={(e) => {
                      valueChange(e, "lane");
                    }}
                    pagination={true}
                    paginationAutoPageSize={true}
                  />
                </div>
              )}
            </FullScreen>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={7.5}>
            {!isPosted(tendersSelectionState) && (
              <Stack direction="column" spacing={1} alignItems="center">
                <FormControl>
                  <RadioGroup
                    defaultValue="close"
                    value={editTenderToggle}
                    onChange={handleChangeSwitch}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={
                        <Typography align="left">
                          <strong>Edit Tender:</strong> if this option is
                          selected you can edit tender data
                        </Typography>
                      }
                    />
                    {!isPosted(changesDone) && (
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={
                          <Typography align="left">
                            <strong>Select Lanes:</strong> if this option is
                            selected you can edit the lanes corresponding to the
                            selected tender
                          </Typography>
                        }
                      />
                    )}
                    {isPosted(changesDone) && (
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        disabled
                        label={
                          <Typography align="left">
                            <strong>Select Lanes:</strong> if this option is
                            selected you can edit the lanes corresponding to the
                            selected tender
                          </Typography>
                        }
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Stack>
            )}
          </Grid>
          <Grid item xs={2}>
            {isPosted(changesDone) && (
              <Button
                variant="outlined"
                size="large"
                onClick={postChanges}
                color="inherit"
              >
                Post Changes
              </Button>
            )}
            {!isPosted(changesDone) && (
              <Button variant="outlined" size="large" disabled color="inherit">
                Post Changes
              </Button>
            )}
          </Grid>
          <Grid item xs={2}>
            {isPosted(tendersSelectionState) && (
              <Button
                variant="outlined"
                size="large"
                onClick={resetState}
                color="inherit"
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      )}
    </div>
  );
};

export default EditTender;
