import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useState } from "react";
import { ColDef } from "ag-grid-community";

function isLeftSide(colDef: ColDef, columnDefs: ColDef[]) {
  const index = columnDefs.indexOf(colDef);
  return index >= 0 && index < Math.floor(columnDefs.length / 2); // Check for left half
}

const DisplayColMenuItem = ({ colDef, gridApi, columnDefs }) => {
  let pinnedState = isLeftSide(colDef, columnDefs) ? "left" : "right";

  let isPinned = colDef.pinned != false && colDef.pinned != undefined;
  const [pinned, setPinned] = useState(isPinned);

  const saveFilter = (columnDefs) => {
    gridApi.setColumnDefs(columnDefs);
    localStorage.setItem("filter", JSON.stringify(columnDefs));
  };


  return (
    <MenuItem
      onClick={() => {
        let nowPinned = colDef.pinned == false || colDef.pinned == undefined;
        setPinned(nowPinned);
        colDef.pinned = nowPinned ? pinnedState : false;
        saveFilter(columnDefs);
      }}
    >
      <input type="checkbox" checked={pinned}></input>
      {colDef.headerName}
    </MenuItem>
  );
};

export default DisplayColMenuItem;
