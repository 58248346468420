import React from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const CheckCellRenderer = ({value, rowData}) => {
  if (value) {
    return <CheckCircleOutlineOutlinedIcon color={"success"}/>
  } else {
    return <CancelOutlinedIcon color={"error"}/>
  }
};

export default CheckCellRenderer;