import './App.css';
import { useEffect, useState } from "react";
// import Home from "./components/Home";
import ResponsiveDrawer from './components/Nav';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [userType, setUser] = useState();
  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true);
      setUser(localStorage.getItem('usertype'));
    }
  }, [isAuth])

  return (
    <div className="App">
      <ResponsiveDrawer />
    </div>
  );
}

export default App;
