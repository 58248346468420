import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AgGridReact } from "ag-grid-react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { API_URL_MAPPING } from "../../configuration/Configuration";
import {
  menuItems,
  enrichTableColumns,
  isPosted,
} from "../../configuration/Utils";
import { useEffect } from "react";
import TableControls from "../../integrations/core/table/TableControls";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

let formData = {};
let columnsChanged = [];

const mappingFields = {
  0: "originCountry",
  1: "destinationCountry",
  2: "originCity",
  3: "destinationCity",
  4: "originZIP",
  5: "destinationZIP",
  6: "equipment",
  7: "payload",
  8: "transitTime",
  9: "palletExchange",
  10: "requirements",
  11: "oneway",
  12: "customerLaneID",
  13: "volume",
  14: "volumeUnit",
};

const Mapping = (props) => {
  const [selection, setSelection] = React.useState([]);
  const [fileStatus, setFileStatus] = React.useState(false);

  useEffect(() => {
    let intSelection = props.uploadFile.columns.slice();
    intSelection = ["- no Mapping -"].concat(intSelection);
    setSelection(intSelection);
  }, []);

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  // reset state before component leave
  const handleUnload = () => {
    setFileStatus(false);
    columnsChanged = [];
  };

  const [var0, setVar0] = React.useState("");
  const [var1, setVar1] = React.useState("");
  const [var2, setVar2] = React.useState("- no Mapping -");
  const [var3, setVar3] = React.useState("- no Mapping -");
  const [var4, setVar4] = React.useState("");
  const [var5, setVar5] = React.useState("");
  const [var6, setVar6] = React.useState("- no Mapping -");
  const [var7, setVar7] = React.useState("- no Mapping -");
  const [var8, setVar8] = React.useState("- no Mapping -");
  const [var9, setVar9] = React.useState("- no Mapping -");
  const [var10, setVar10] = React.useState("- no Mapping -");
  const [var11, setVar11] = React.useState("- no Mapping -");
  const [var12, setVar12] = React.useState("- no Mapping -");
  const [var13, setVar13] = React.useState("");
  const [var14, setVar14] = React.useState("- no Mapping -");
  const handleStateChange1 = (event, id) => {
    switch (id) {
      case "0":
        setVar0(event.target.value);
        break;
      case "1":
        setVar1(event.target.value);
        break;
      case "2":
        setVar2(event.target.value);
        break;
      case "3":
        setVar3(event.target.value);
        break;
      case "4":
        setVar4(event.target.value);
        break;
      case "5":
        setVar5(event.target.value);
        break;
      case "6":
        setVar6(event.target.value);
        break;
      case "7":
        setVar7(event.target.value);
        break;
      case "8":
        setVar8(event.target.value);
        break;
      case "9":
        setVar9(event.target.value);
        break;
      case "10":
        setVar10(event.target.value);
        break;
      case "11":
        setVar11(event.target.value);
        break;
      case "12":
        setVar12(event.target.value);
        break;
      case "13":
        setVar13(event.target.value);
        break;
      case "14":
        setVar14(event.target.value);
        break;
      default:
        console.log("ERROR");
    }
  };

  const [tableData, setTable] = React.useState([]);
  const postMapping = async (final) => {
    // Upload file: post via axios endpoint and get resulting feedback
    let interDict = {};
    for (let i = 0; i < 15; i++) {
      eval("interDict[mappingFields[" + i + "]] = var" + i);
    }
    columnsChanged = [];
    for (const [key, value] of Object.entries(interDict)) {
      if (value && value !== "None") {
        formData[key] = value;
        columnsChanged.push(key);
      }
    }
    formData["columns"] = columnsChanged;
    formData["rows"] = props.uploadFile.rows;
    formData["tenderID"] = props.tender;
    console.log(formData);
    await axios
      .post(API_URL_MAPPING, formData)
      .then((res) => setTable(res.data))
      .then(console.log("mapping posted", tableData));
    await props.func(tableData);
    setFileStatus(true);
    if (final) {
      props.handleNext();
    }
  };

  const gridStyle = React.useMemo(() => ({ height: 540, width: "100%" }), []);
  let fullScreenHandle = useFullScreenHandle();
  const gridRef = React.useRef();

  return (
    <Box>
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item xs={8}>
          <Typography align="left" sx={{ mt: 2, mb: 1 }}>
            <strong>
              Define how the columns correspond to the essential lane
              information.
            </strong>
            <br />
            Fields with the value '- no Mapping -' are optional fields which can
            be selected or left as is.
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1, fontSize: 14, color: "#a2232b" }}>
            Lane Information
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1, fontSize: 14, color: "#a2232b" }}>
            Input Column from File
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1, fontSize: 14, color: "#a2232b" }}>
            Lane Information
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1, fontSize: 14, color: "#a2232b" }}>
            Input Column from File
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        {/* Next line */}
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Origin Country</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl error sx={{ width: "80%" }}>
            <InputLabel>Required</InputLabel>
            <Select
              value={String(var0)}
              label="Required"
              sx={{ width: "100%" }}
              onChange={(e) => handleStateChange1(e, "0")}
            >
              {menuItems(selection)}
            </Select>
          </FormControl>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Destination Country</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl error sx={{ width: "80%" }}>
            <InputLabel>Required</InputLabel>
            <Select
              value={var1}
              label="Required"
              sx={{ width: "100%" }}
              onChange={(e) => handleStateChange1(e, "1")}
            >
              {menuItems(selection)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}></Grid>

        {/* Next line */}
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Origin City</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var2}
            label=""
            sx={{ width: "80%" }}
            displayEmpty
            onChange={(e) => handleStateChange1(e, "2")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Destination City</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var3}
            label=""
            sx={{ width: "80%" }}
            onChange={(e) => handleStateChange1(e, "3")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Grid item xs={2}></Grid>
        {/* Next line */}
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Origin ZIP</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl error sx={{ width: "80%" }}>
            <InputLabel>Required</InputLabel>
            <Select
              value={var4}
              label="Required"
              sx={{ width: "100%" }}
              onChange={(e) => handleStateChange1(e, "4")}
            >
              {menuItems(selection)}
            </Select>
          </FormControl>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Destination ZIP</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl error sx={{ width: "80%" }}>
            <InputLabel>Required</InputLabel>
            <Select
              value={var5}
              label="Required"
              sx={{ width: "100%" }}
              onChange={(e) => handleStateChange1(e, "5")}
            >
              {menuItems(selection)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Box sx={{ m: 1 }} />
      <Divider />
      <Box sx={{ m: 1 }} />
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Equipment</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var6}
            label=""
            sx={{ width: "80%" }}
            onChange={(e) => handleStateChange1(e, "6")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Payload</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var7}
            label=""
            sx={{ width: "80%" }}
            // onChange={handleStateChange1}
            onChange={(e) => handleStateChange1(e, "7")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Grid item xs={2}></Grid>
        {/* Next line */}
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Transit Time</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var8}
            label=""
            sx={{ width: "80%" }}
            // onChange={handleStateChange1}
            onChange={(e) => handleStateChange1(e, "8")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Pallet Exchange</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var9}
            label=""
            sx={{ width: "80%" }}
            // onChange={handleStateChange1}
            onChange={(e) => handleStateChange1(e, "9")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Grid item xs={2}></Grid>
        {/* Next line */}
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Special Requirements</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var10}
            label=""
            sx={{ width: "80%" }}
            // onChange={handleStateChange1}
            onChange={(e) => handleStateChange1(e, "10")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Oneway/Roundtrip</Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var11}
            label=""
            sx={{ width: "80%" }}
            // onChange={handleStateChange1}
            onChange={(e) => handleStateChange1(e, "11")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Grid item xs={2}></Grid>
        {/* Next line */}
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Lane ID provided by Customer
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var12}
            label=""
            sx={{ width: "80%" }}
            // onChange={handleStateChange1}
            onChange={(e) => handleStateChange1(e, "12")}
          >
            {menuItems(selection)}
          </Select>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2}>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Payload</Typography> */}
        </Grid>
        <Grid item xs={2}>
          {/* <Select
            value={destinationCity}
            label="valpayment"
            sx={{ width: '80%' }}
          // onChange={handleStateChange1}
          >
            {menuItems(selection)}
          </Select> */}
        </Grid>
        <Grid item xs={2}></Grid>
        {/* Next line */}
      </Grid>
      <Box sx={{ m: 1 }} />
      <Divider />
      <Box sx={{ m: 1 }} />
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ mt: 2, mb: 1 }}>Annual Volume + Unit</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl error sx={{ width: "80%" }}>
            <InputLabel>Required</InputLabel>
            <Select
              value={var13}
              label="Required"
              sx={{ width: "100%" }}
              onChange={(e) => handleStateChange1(e, "13")}
            >
              {menuItems(selection)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={var14}
            label=""
            sx={{ width: "80%" }}
            onChange={(e) => handleStateChange1(e, "14")}
          >
            <MenuItem value={"- no Mapping -"}>- no Mapping -</MenuItem>
            <MenuItem value={"Transports"}>Transports</MenuItem>
            <MenuItem value={"Tons"}>Tons</MenuItem>
            <MenuItem value={"m3"}>m^3</MenuItem>
            <MenuItem value={"Nr Palettes"}>Nr. Palettes</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={11} align="right" sx={{ mt: 3 }}>
          <Button
            variant="outlined"
            size="large"
            onClick={() => postMapping(false)}
            color="inherit"
          >
            Preview
          </Button>
        </Grid>
        <Grid item xs={9} align="left"></Grid>

        <Grid item xs={0.5}></Grid>
        <Grid item xs={11}>
          {isPosted(tableData.missing) && (
            <Typography align="left" sx={{ mt: 2, mb: 1 }}>
              <strong>
                Some relevant columns are still missing. Please specifiy the
                columns in your file that contain the following lane
                information:
              </strong>
            </Typography>
          )}
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={0.5}></Grid>

        <Grid item xs={12}>
          {isPosted(fileStatus) && (
            <FullScreen handle={fullScreenHandle}>
              <TableControls
                table={gridRef}
                fullScreenHandle={fullScreenHandle}
              />
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={tableData.rows} // Row Data for Rows
                  columnDefs={enrichTableColumns(tableData)} // Column Defs for Columns
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            </FullScreen>
          )}
        </Grid>
        <Grid align="right" xs={12} sx={{ mt: 3 }}>
          {isPosted(fileStatus) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => postMapping(true)}
            >
              Post Mapping
            </Button>
          )}
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
    </Box>
  );
};
export default Mapping;
