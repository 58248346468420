import ColFreezeMenu from "./controls/ColFreezeMenu";
import FullScreenButton from "./controls/FullcreenButton";
import { Fragment, useEffect } from "react";
import React from "react";
import ColDisplayMenu from "./controls/ColDisplayMenu";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import { API_URL_TENDERS_EXPORT } from "../../../configuration/Configuration";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const tableControlStyle = {
  border: "none",
  color: "black",
  "&:hover": {
    backgroundColor: "transparent",
    color: "black",
    textDecoration: "underline",
    transition: "none", // Disable transition animations
  },
  "&:active": {
    color: "#1876D2",
  },
};

const TableControls = ({
  table,
  fullScreenHandle,
  ref,
  tenders,
  user,
  showImport,
  showExport,
}) => {
  const navigate = useNavigate();


  if (table.current && table.current.columnApi) {
    let columnApi = table.current.columnApi;
    columnApi.autoSizeAllColumns();
    columnApi.autoSizeColumns();
  }

  const downloadFile = async (tenders) => {
    const url_api_file = `${API_URL_TENDERS_EXPORT}${user}/${tenders}/lane/`;

    try {
      const response = await axios.get(url_api_file, {
        responseType: "blob",
      });
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition.split("filename=")[1].split("?")[0];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Fragment>
      <div style={{ textAlign: "right" }}>
        {showExport && (
          <Button
            startIcon={<FileDownloadOutlinedIcon />}
            sx={tableControlStyle}
            onClick={() => {
              downloadFile(tenders);
            }}
          >
            EXPORT
          </Button>
        )}
        {showImport && (
          <Button
            startIcon={<FileUploadOutlinedIcon />}
            sx={tableControlStyle}
            onClick={() => {
              navigate(`/import/?id=${tenders}`);
            }}
          >
            IMPORT
          </Button>
        )}

        <FullScreenButton fullScreenHandle={fullScreenHandle} />
        <ColFreezeMenu table={table} ref={ref} />
        <ColDisplayMenu table={table} />
      </div>
    </Fragment>
  );
};
export default TableControls;
