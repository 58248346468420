import { Menu, PopoverOrigin } from "@mui/material";
import Button from "@mui/material/Button";
import { ColDef, GridApi } from "ag-grid-community";
import { tableControlStyle } from "../TableControls";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FeezeColMenuItem from "./FreezeColMenuItem";
import { Fragment, useRef, useState } from "react";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const menuAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const ColFreezeMenu = ({ table, reference }) => {
  
  if (!(table.current && table.current.api)) {
    return <div></div>;
  }
  const gridApi: GridApi = table.current.api;
  let columnApi = table.current.columnApi;
  if (!columnApi?.columnModel) {
    return <div></div>;
  }
  const columnDefs = columnApi.columnModel.columnDefs as ColDef[];

  if (!columnDefs) {
    return <div></div>;
  }
  const [colFreezeOpened, setColFreezeOpened] = useState(false);
  const colFreezeBtn = useRef();
  let colFreezeItems = columnDefs.map((colDef: ColDef) => (
    <FeezeColMenuItem
      key={colDef.field}
      colDef={colDef}
      gridApi={gridApi}
      columnDefs={columnDefs}
    />
  ));
  let colFreezeMenu = (
    <Menu
      open={colFreezeOpened}
      onClose={() => setColFreezeOpened(false)}
      anchorEl={colFreezeBtn.current}
      anchorOrigin={menuAnchorOrigin}
      container={document.getElementById("fullScreen-wrap")}
    >
      {colFreezeItems}
    </Menu>
  );
  return (
    <Fragment>
      <Button
        startIcon={<LockOpenIcon />}
        endIcon={<ArrowDropDownIcon />}
        ref={colFreezeBtn}
        sx={tableControlStyle}
        onClick={() => {
          setColFreezeOpened(!colFreezeOpened);
        }}
      >
        Freeze
      </Button>
      {colFreezeMenu}
    </Fragment>
  );
};

export default ColFreezeMenu;
