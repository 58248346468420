import axios from "axios";
import { API_URL_FILEUPLOAD_UPDATE_LANE } from "../configuration/Configuration";

const loadFile = (file, tender_id) => {
  // Create an object of formData
  const formData = new FormData();
  // Update the formData object
  formData.append("excel_file", file, file.name);
  // Upload file: post via axios endpoint and get resulting feedback

  const user = localStorage.getItem("user");

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_FILEUPLOAD_UPDATE_LANE + user + "/" + tender_id, formData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export default loadFile;
