import React from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FileUploader } from "react-drag-drop-files";
import { AgGridReact } from 'ag-grid-react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { fileTypes, API_URL_FILEUPLOAD } from '../../configuration/Configuration';
import { enrichTableColumns, isPosted } from '../../configuration/Utils';
import TableControls from "../../integrations/core/table/TableControls";
import {FullScreen, useFullScreenHandle} from "react-full-screen";

const FileUpload = (props) => {
  const [fileStatus, setFileStatus] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  // eslint-disable-next-line
  const [file, setFile] = React.useState(null);
  // set initially chosen header row, sheet number to 1
  var [headerRow, setHeaderRow] = React.useState(1);
  var [sheetNumber, setSheetNumber] = React.useState(1);
  const handleHeaderRow = (input) => {
    if (input.target.value > 0) {
      setHeaderRow(input.target.value);
    }
    if (fileStatus) {
      handleFileChange(file);
      console.log("Status;", fileStatus)
    }
  };

  const handleSheetNumber = (input) => {
    if (input.target.value > 0) {
      setSheetNumber(input.target.value);
    }
    if (fileStatus) {
      console.log("Status;", fileStatus)
      handleFileChange(file);
    }
  };

  const handleUnload = () => {
    setFileStatus(false);
  };

  const [fileData, setFileData] = React.useState([]);
  var uploadData = [];

  
  // handle upload: file is applied, upload status set to true
  const handleFileChange = async (file) => {
    setFile(file);
    await loadFile(file)
    console.log("updated file data:", fileData);
    setFileStatus(true);
    uploadData = fileData;
    await props.func(uploadData);
  };

  function loadFile(file) {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "excel_file",
      file,
      file.name
    );
    // Upload file: post via axios endpoint and get resulting feedback
    axios.post(API_URL_FILEUPLOAD + "header" + headerRow + "/sheet" + sheetNumber, formData)
      .then(res => setFileData(res.data))
      .then(console.log("file posted"));
  };

  const loadFileButton = async () => {
    uploadData = fileData;
    props.func(uploadData);
    props.handleNext()
  }

  let fullScreenHandle = useFullScreenHandle();
  const gridRef = React.useRef();
  const gridStyle = React.useMemo(() => ({ height: 540, width: '100%' }), []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item xs={9} sx={{mt:5}}>
          <Typography align="left" sx={{ mt: 2, mb: 1 }} ><strong>Upload the lane information. The lanes should be rows in an Excel file, the columns should contain origin, destination and requested volume.</strong></Typography>
        </Grid>
        <Grid item xs={4}>
          <FileUploader style={{ width: "50" }} handleChange={handleFileChange} name="file" types={fileTypes} />          
        </Grid>
        <Grid item xs={8}> </Grid>
        <Grid item xs={12} sx={{mt:3}}> </Grid>

        <Grid item xs={2} align="left">
          <TextField value={headerRow} onChange={handleHeaderRow} type="number" label="Header Row" sx={{ width: '80%' }} />
        </Grid>
        <Grid item xs={2} align="left">
          <TextField value={sheetNumber} onChange={handleSheetNumber} type="number" label="Sheet Nr" sx={{ width: '80%' }} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Divider />
        {/* <Grid item xs={2} ></Grid> */}
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={12}>
          {isPosted(fileStatus) && (
            <FullScreen handle={fullScreenHandle}>
              <TableControls table={gridRef} fullScreenHandle={fullScreenHandle}/>
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef} // Ref for accessing Grid's API
                  rowData={fileData.rows} // Row Data for Rows
                  columnDefs={enrichTableColumns(fileData)} // Column Defs for Columns
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            </FullScreen>
          )}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}  align="left">
          {isPosted(fileStatus) && (
            <Button variant="contained" sx={{ width: '75%' }} size="medium" onClick={loadFileButton}>
              Confirm
            </Button>
          )}
          {!isPosted(fileStatus) && (
            <Button variant="contained" sx={{ width: '75%' }} size="medium" disabled>
              Upload
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default FileUpload;