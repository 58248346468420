// Import the react JS packages 
import axios from "axios";
import { useState } from "react";// Define the Login function.
import Input from "@mui/material/Input"
import Button from "@mui/material/Button"
import { API_URL_TENDERS, API_BASE_URL } from "../configuration/Configuration";

const Export = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');     // Create the submit method.
  const submit = async e => {
    e.preventDefault();
    const user = {
      username: username.trim(),
      password: password
    };
    // Create the POST requuest
    const { data } = await axios.post(`${API_BASE_URL}/users/token/`, user);
    // Initialize the access & refresh token in localstorage.   
    localStorage.clear();
    localStorage.setItem('user', username.trim());
    if (username.trim() === "admin" || username.trim() === "tender-management") {
      localStorage.setItem('usertype', "admin");
    } else if (username.trim() === "regional-department1" || username.trim() === "regional-department2") {
      localStorage.setItem('usertype', "regional");
    } else if (username.trim() === "finance-department" || username.trim() === "qsu-department" || username.trim() === "legal-department") {
      localStorage.setItem('usertype', "checks");
    }
    try {
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
      window.location.href = '/';
    }
    catch {
      setPassword("");
      setUsername("");
    }
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={submit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            {/* <label>Username  </label> */}
            <Input className="form-control mt-1"
              placeholder="Enter Username"
              name='username'
              type='text' value={username}
              required
              onChange={e => setUsername(e.target.value)} />
          </div>
          <div className="form-group mt-3">
            {/* <label>Password  </label> */}
            <Input name='password'
              type="password"
              className="form-control mt-1"
              placeholder="Enter Password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)} />
          </div>
          <div className="d-grid gap-2 mt-3">
            <Button type="submit"
              className="btn btn-primary">Submit</Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Export;