import React, {useEffect} from "react";
import axios from "axios";
import {AgGridReact} from 'ag-grid-react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  API_URL_LANES_PRICING,
  API_URL_TENDERS_FINAL,
  tenderColumnsDefinitionOverview
} from '../configuration/Configuration'
import {headerColumns, isPosted} from "../configuration/Utils";
import {useLocation} from 'react-router-dom';
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import TableControls from "../integrations/core/table/TableControls";

const TenderOverview = () => {
  const { key } = useLocation();
  useEffect(() => {
    resetState();
    getTenderDataOverview();
  }, [key]);
  React.useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  // eslint-disable-next-line
  let [tendersSelectionSate, setTenderSelectState] = React.useState(false);

  // retrieve tender overview 
  let [tenders, setOverview] = React.useState([]);
  // eslint-disable-next-line
  React.useEffect(() => {
    const opt = [];

    (async () => {
      const { data } = await axios.get(API_URL_TENDERS_FINAL);
      data.forEach((value) => {
        opt.push(value);
      });
    })();
    setOverview(opt);
  }, []);

  let fullScreenHandle = useFullScreenHandle();
  const gridRef = React.useRef();
  const gridStyle = React.useMemo(() => ({ height: 540, width: '100%' }), []);

  const onTenderSelected = (params) => {
    getTenderData(params.data.id);
    setTenderSelectState(true);
  };

  const [tenderData, setTenderData] = React.useState({rows:[]});
  const [gridAdmin, setGridAdmin] = React.useState([]);
  const getTenderData = (tender) => {
    (async () => {
      const url_api = API_URL_LANES_PRICING + tender + "/admin"
      const { data } = await axios.get(url_api);
      setTenderData(data);
      let laneColumnDefinitionAdmin = await (headerColumns(tender, true))
      setGridAdmin(laneColumnDefinitionAdmin);
    })();
  };

  const getTenderDataOverview = () => {
    (async () => {
      const opt = [];
      const { data } = await axios.get(API_URL_TENDERS_FINAL);
      data.forEach((value: any) => {
        opt.push(value);
      });
      setOverview(opt);
    })();
  };

  const resetState = () => {
    setTenderSelectState(false);
  };

  // reset states before unload
  const handleUnload = () => {
    setTenderSelectState(false);
    setOverview([]);
  };

  return (
    <div className="App">
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={0.5}></Grid>
        <Grid item xs={11.5}>
          <Typography variant="h6" align="left">Get an overview over tenders that reached their final state.</Typography>
        </Grid>
        {/* <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Button variant="outlined" size="large" onClick={getTenderDataOverview} color="inherit" >
            Load Data
          </Button>
        </Grid>
        <Grid item xs={8}></Grid> */}
        <Grid item xs={0.5}></Grid>
        <Grid item xs={12}>
          <FullScreen handle={fullScreenHandle}>
            <TableControls table={gridRef} fullScreenHandle={fullScreenHandle}/>
            {isPosted(!tendersSelectionSate) && (
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef} // Ref for accessing Grid's API
                  rowData={tenders} // Row Data for Rows
                  columnDefs={tenderColumnsDefinitionOverview} // Column Defs for Columns
                  onRowDoubleClicked={onTenderSelected}
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            )}
            {isPosted(tendersSelectionSate) && (
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact ref={gridRef} // Ref for accessing Grid's API
                  rowData={tenderData.rows} // Row Data for Rows
                  columnDefs={gridAdmin} // Column Defs for Columns
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            )}
          </FullScreen>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          {isPosted(tendersSelectionSate) && (
            <Button variant="outlined" size="large" onClick={resetState} color="inherit" >
              Back
            </Button>
          )}
          {isPosted(!tendersSelectionSate) && (
            <Button variant="outlined" size="large" disabled color="inherit" >
              Back
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default TenderOverview;
