import MenuItem from "@mui/material/MenuItem";
import React, {useEffect} from "react";
import {useState} from "react";

const ColDisplayMenuItem = ({override, colDef, gridApi, columnDefs, onUpdate}) => {
  const [colVisible, setColVisible] = useState(!colDef.hide);
  /*
  useEffect(() => {
    if (override && colDef.hide) {
      let nowHidden = false;
      setColVisible(!nowHidden);
      colDef.hide = nowHidden;
      gridApi.setColumnDefs(columnDefs)
      onUpdate()
    }

  }, [override]);
  */

    console.log(colDef, 'e')
  const saveFilter = (columnDefs) => {
    gridApi.setColumnDefs(columnDefs);
    localStorage.setItem("filter", JSON.stringify(columnDefs));
  };

  return <MenuItem onClick={() => {
    let nowHidden = colDef.hide == false || colDef.hide == undefined;
    setColVisible(!nowHidden);
    colDef.hide = nowHidden;
    saveFilter(columnDefs);
    onUpdate()
  }}>
    <input
      type="checkbox"
      checked={colVisible}
      onChange={() => {
      }}
    >
    </input>
    {colDef.headerName}
  </MenuItem>
};

export default ColDisplayMenuItem;