import CheckCellRenderer from "../integrations/core/table/cells/CheckCellRenderer";
import PriorityCellRenderer from "../integrations/core/table/cells/PriorityCellRenderer";

// steps for new tender definition
export const steps = ['Customer', 'Tender Data', 'File Upload', 'Transformation',
  'Mapping', 'Enrichments', 'Validation'];

export const optionalSteps = [3, 5];

// file types for upload
export const fileTypes = ["XLSX"];
export const dateFormatter = (params: { value: string | number | Date; }) => {
  const date = new Date(params.value);
  if (params.value === null || params.value === "") {
    return ""
  }
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return day + '/' + month + '/' + year;
};
function statusFormatter(value: { value: any; }) {
  switch (value.value) {
    case "Round 1 Started":
      return "Started Round 1"
    case "Round 1 Prices":
      return "Pricing Round 1"
    case "Round 1 Finalized":
      return "Finalize Round 1"
    case "Round 2 Feedback":
      return "Feedback Customer Round 1"
    case "Round 2 Prices":
      return "Pricing Round 2"
    case "Round 2 Finalized":
      return "Finalize Round 2"
    case "Round 3 Feedback":
      return "Feedback Customer Round 2"
    case "Round 3 Prices":
      return "Pricing Round 3"
    case "Round 3 Finalized":
      return "Finalize Round 3"
    case "Accepted":
      return "Accepted"
    case "Closed":
      return "Closed"
    default:
      return "Rejected"
  }
}
function prioFormatter(value: { value: any; }) {
  switch (value.value) {
    case 1:
      return "low";
    case 3:
      return "high";
    default:
      return "medium";
  }
}

function termFormatter(value: { value: any; }) {
  switch (value.value) {
    case "ImmediateSingle":
      return "Immediate Single"
    case "WeeklyCollective":
      return "Weekly Collective"
    case "BiweeklyCollective":
      return "Biweekly Collective"
    case "MonthlyCollective":
      return "Monthly Collective"
    default:
      return ""
  }
}

export const auditFormatter = (value: { value: boolean; }) => {
  return value.value === true ? "\u2714" : "\u2718"
}
export const dateSort = (valueA: string, valueB: string) => {
  let yearA = valueA.substring(6)
  let yearB = valueB.substring(6)
  let monthA = valueA.substring(3, 5)
  let monthB = valueB.substring(3, 5)
  let dayA = valueA.substring(0, 2)
  let dayB = valueB.substring(0, 2)
  if (yearA > yearB) {
    return 1
  } else if (yearA < yearB) {
    return -1
  } else if (monthA > monthB) {
    return 1
  } else if (monthA < monthB) {
    return -1
  } else if (dayA > dayB) {
    return 1
  } else if (dayA < dayB) {
    return -1
  }
  return 0
}

export const filterOptions = {
  suppressAndOrCondition: true,
  filterOptions:
    [
      "empty",
      {
        displayKey: "\u2714",
        displayName: "\u2714",
        predicate: (_: any, cellValue: boolean) => cellValue != null && cellValue === true,
        numberOfInputs: 0,
      },
      {
        displayKey: "\u2718",
        displayName: "\u2718",
        predicate: (_: any, cellValue: boolean) => cellValue != null && cellValue === false,
        numberOfInputs: 0,
      },
    ]
}



const filterOptionsInvoicing = {
  filterOptions:
    [
      "empty",
      {
        displayKey: "Immediate Single",
        displayName: "Immediate Single",
        predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "ImmediateSingle",
        numberOfInputs: 0,
      },
      {
        displayKey: "Weekly Collective",
        displayName: "Weekly Collective",
        predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "WeeklyCollective",
        numberOfInputs: 0,
      },
      {
        displayKey: "Biweekly Collective",
        displayName: "Biweekly Collective",
        predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "biweeklyCollective",
        numberOfInputs: 0,
      },
      {
        displayKey: "Monthly Collective",
        displayName: "Monthly Collective",
        predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "MonthlyCollective",
        numberOfInputs: 0,
      },
    ]
}

const filterOptionsStatus = {
  suppressAndOrCondition: true,
  filterOptions: [
    "empty",
    {
      displayKey: "Started Round 1",
      displayName: "Started Round 1",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 1 Started",
      numberOfInputs: 0,
    },
    {
      displayKey: "Pricing Round 1",
      displayName: "Pricing Round 1",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 1 Prices",
      numberOfInputs: 0,
    },
    {
      displayKey: "Finalize Round 1",
      displayName: "Finalize Round 1",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 1 Finalized",
      numberOfInputs: 0,
    },
    {
      displayKey: "Feedback Customer Round 1",
      displayName: "Feedback Customer Round 1",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 2 Feedback",
      numberOfInputs: 0,
    },
    {
      displayKey: "Pricing Round 2",
      displayName: "Pricing Round 2",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 2 Prices",
      numberOfInputs: 0,
    },
    {
      displayKey: "Finalize Round 2",
      displayName: "Finalize Round 2",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 2 Finalized",
      numberOfInputs: 0,
    },
    {
      displayKey: "Feedback Customer Round 2",
      displayName: "Feedback Customer Round 2",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 3 Feedback",
      numberOfInputs: 0,
    },
    {
      displayKey: "Pricing Round 3",
      displayName: "Pricing Round 3",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 3 Prices",
      numberOfInputs: 0,
    },
    {
      displayKey: "Finalize Round 3",
      displayName: "Finalize Round 3",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Round 3 Finalized",
      numberOfInputs: 0,
    },
    {
      displayKey: "Accepted",
      displayName: "Accepted",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Accepted",
      numberOfInputs: 0,
    },
    {
      displayKey: "Closed",
      displayName: "Closed",
      predicate: (_: any, cellValue: string) => cellValue != null && cellValue === "Closed",
      numberOfInputs: 0,
    },
    {
      displayKey: "Rejected",
      displayName: "Rejected",
      predicate: (_: any, cellValue: string | string[]) => cellValue != null && cellValue.indexOf("Rejected") >= 0,
      numberOfInputs: 0,
    },
  ]
}


export const filterOptionsDate = {
  filterOptions:
    [
      {
        displayKey: "Year",
        displayName: "Year",
        predicate: ([fv1]: any, cellValue: string) => cellValue != null && (cellValue.substring(6) === fv1 || cellValue.substring(8) === fv1),
        numberOfInputs: 1,
      },
      {
        displayKey: "Month",
        displayName: "Month",
        predicate: ([fv1]: any, cellValue: string) => cellValue != null && (cellValue.substring(3, 5) === fv1 || cellValue.at(4) === fv1),
        numberOfInputs: 1,
      },
      {
        displayKey: "Day",
        displayName: "Day",
        predicate: ([fv1]: any, cellValue: string) => cellValue != null && (cellValue.substring(0, 2) === fv1 || cellValue.at(1) === fv1),
        numberOfInputs: 1,
      },
    ]
}


export const tenderColumnsDefinitionOverview = [
  { field: 'company', headerName: 'Company Name', resizable: true, filter: true, sortable: true },
  {
    field: 'tender_status', headerName: 'Tender Status', resizable: true, filter: true, sortable: true, minWidth: 0,
    valueFormatter: statusFormatter, filterParams: filterOptionsStatus,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [
        "Round 1 Started", "Round 1 Prices", "Round 1 Finalized",
        "Round 2 Feedback", "Round 2 Prices", "Round 2 Finalized",
        "Round 3 Feedback", "Round 3 Prices", "Round 3 Finalized",
        "Accepted", "Closed"
      ],
    },
  },
  {
    field: 'priority', headerName: 'Priority', filter: true, sortable: true, minWidth: 0, resizable: true,
    cellEditor: 'agSelectCellEditor', //valueFormatter: prioFormatter,
    cellRendererFramework: PriorityCellRenderer,
    cellEditorParams: {
      values: [1, 2, 3]
    },
  },
  {
    field: 'internalDeadline', headerName: 'Internal Deadline',
    filter: 'agDateColumnFilter', sortable: true, minWidth: 0, resizable: true, valueFormatter: dateFormatter,
    cellEditor: "agDateCellEditor"
  },
  {
    field: 'submissionDate', headerName: 'Submission Date',
    filter: 'agDateColumnFilter', sortable: true, minWidth: 0, resizable: true, valueFormatter: dateFormatter,
    cellEditor: "agDateCellEditor"
  },
  {
    field: 'contractStart', headerName: 'Contract Date',
    filter: 'agDateColumnFilter', sortable: true, minWidth: 0, resizable: true,
    valueGetter: (params) => {
      // Access data from other fields using params.data
      const contractStart = params.data.contractStart;
      const contractEnd = params.data.contractEnd;
      return dateFormatter({value:contractStart}) + " - " +
          dateFormatter({value:contractEnd});
    }
  },
  // {
  //  field: 'contractEnd', headerName: 'Contract End',
  //  filter: 'agDateColumnFilter', sortable: true, minWidth: 0, resizable: true, valueFormatter: dateFormatter,
  //  cellEditor: "agDateCellEditor"
  // },
  {
    field: 'invoicing', headerName: 'Invoicing', filter: true, sortable: true, minWidth: 0, width: 180,resizable: true,
    filterParams: filterOptionsInvoicing, valueFormatter: termFormatter,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ["", "ImmediateSingle", "WeeklyCollective", "BiweeklyCollective", "MonthlyCollective"]
    },
  },
  {
    field: 'paymentTerms', headerName: 'Payment Days', cellDataType: "number", filter: true, sortable: true,width: 120, minWidth: 0, resizable: true, cellEditor: "agNumberCellEditor",
    cellEditorParams: {
      min: 0,
      precision: 0,
    }
  },
  {
    field: 'finance_check', headerName: 'Financial', cellClass: 'booleanType', sortable: true, width: 120, minWidth: 0, resizable: true, filter: true,
    filterParams: filterOptions, // valueFormatter: auditFormatter,
    cellRendererFramework: CheckCellRenderer,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [true, false]
    },
  },
  {
    field: 'legal_check', headerName: 'Legal', cellClass: 'booleanType', sortable: true, minWidth: 0,width: 120, resizable: true, filter: true,
    filterParams: filterOptions, // valueFormatter: auditFormatter,
    cellRendererFramework: CheckCellRenderer,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [true, false]
    },
  },
  {
    field: 'qsu_check', headerName: 'QSU', cellClass: 'booleanType', sortable: true, minWidth: 0, resizable: true,width: 120, filter: true,
    filterParams: filterOptions, // valueFormatter: auditFormatter,
    cellRendererFramework: CheckCellRenderer,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [true, false]
    },
  },
  {
    field: 'date_created', headerName: 'Creation Date',
    filter: 'agDateColumnFilter', sortable: true, minWidth: 0, width: 140,resizable: true, valueFormatter: dateFormatter,
  },
];
// marks for customer rating
export const marks = [
  {
    value: 1,
    label: 'low',
  },
  {
    value: 2,
    label: 'medium',
  },
  {
    value: 3,
    label: 'high',
  },
];

// duration	distance_here	tolls	final_price

export const API_BASE_URL = "https://api.albfqngnktbw.net"
//export const API_BASE_URL = "http://localhost:8000"

export const API_URL_COMPANY = `${API_BASE_URL}/api/customers/`;
export const API_URL_RATING = `${API_BASE_URL}/api/customer_rating/`;
export const API_URL_NEW_TENDER = `${API_BASE_URL}/api/new_tenders/`;
export const API_URL_FILEUPLOAD = `${API_BASE_URL}/api/file_upload/`;
export const API_URL_MAPPING = `${API_BASE_URL}/api/mapping/`;
export const API_URL_VALIDATION = `${API_BASE_URL}/api/validation/`;
export const API_URL_POST_LANES = `${API_BASE_URL}/api/validation/save/`;
export const API_URL_TENDER_OVERVIEW = `${API_BASE_URL}/api/tenders_view/`;
export const API_URL_TENDERS = `${API_BASE_URL}/api/tenders_lanes_view/`;
export const API_URL_STATE_UPDATE = `${API_BASE_URL}/api/tenders_state/`;
export const API_URL_LANES_PRICING = `${API_BASE_URL}/api/lanes_pricing/`;
export const API_URL_PRICES_UPDATE = `${API_BASE_URL}/api/post_lanes_pricing/`;
export const API_URL_TENDERS_FINAL = `${API_BASE_URL}/api/tenders_final_view/`;
export const API_URL_TENDERS_EXPORT = `${API_BASE_URL}/api/tenders_export/`;
export const API_URL_TENDERS_DELETE = `${API_BASE_URL}/api/delete_tender/`;
export const API_URL_REGIONAL_STATUS = `${API_BASE_URL}/api/tenders_status_regional/`;
export const API_URL_CLEANUP = `${API_BASE_URL}/api/cleanup/`;
export const API_URL_DEPARTMENTS = `${API_BASE_URL}/api/get_departments/`;
export const API_URL_COLUMNS = `${API_BASE_URL}/api/get_columns/`;
export const API_URL_STATUS = `${API_BASE_URL}/api/tender_status/`;
export const API_URL_EDIT = `${API_BASE_URL}/api/edit_tender/`;
export const API_URL_FILEUPLOAD_UPDATE_LANE = `${API_BASE_URL}/api/lanes/update/`;
