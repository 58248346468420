
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
// import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import GridViewIcon from '@mui/icons-material/GridView';
import BusinessIcon from '@mui/icons-material/Business';

export const pages = [
    {
        name: 'New Tender',
        icon: <CreateNewFolderRoundedIcon />,
        path: '/NewTender'
    },
    {
        name: 'Manage Tender',
        icon: <RotateRightIcon />,
        path: '/AdvanceTender'
    },
    /*{
        name: 'Analytics',
        icon: <QueryStatsRoundedIcon />,
        path: '/Analytics'
    },*/
   
    {
        name: 'Finalized Tenders',
        icon: <GridViewIcon />,
        path: '/TenderOverview'
    },
    {
        name: 'Departments',
        icon: <BusinessIcon />,
        path: '/departments'
    },
];

export const adminPages = [
    {
        name: 'Export Tender',
        icon: <FileDownloadRoundedIcon />,
        path: '/Export'
    },
    {
        name: 'Admin Overview',
        icon: <AccountBoxRoundedIcon />,
        path: '/Admin'
    },
    {
        name: 'Edit Tender',
        icon: <CreateRoundedIcon />,
        path: '/EditTender'
    },
    
];


export const hiddenPages = [

    {
        name: 'Import ',
        icon: <AccountBoxRoundedIcon />,
        path: '/Import'
    }
]
