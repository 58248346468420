import { useEffect, useState } from "react";
import L from "leaflet";
import CloseIcon from '@mui/icons-material/Close';

const Map = () => {
  const [mapPolyline, setMapPolyline] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  document.addEventListener("polyLineCreated", function (e) {
    setMapPolyline(e.detail);
  });


  useEffect(() => {
    if (mapPolyline) {
      document.getElementById("mapContainer").style.height = "300px";
      document.getElementById("mapContainer").style.overflow = "visible";
      document.getElementById("close").style.display = "flex";

      var container = L.DomUtil.get("map");

      if (container != null) {
        container._leaflet_id = null;
      }
      let map = new L.Map("map", {
        center: [48.61, 6.125],
        zoom: 4,
        dragging: true,
      });

      const here = {
        apiKey: "Ygvd9yX9WNrO71olru-C3FMJpfwFenXDe6c1VQfeYxs",
      };
      const style = "normal.day";

      const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`;

      let layerOptions = {};

      new L.TileLayer(hereTileUrl, layerOptions).addTo(map);

      const routePolyline = [{ type: "LineString", coordinates: mapPolyline }];

      new L.GeoJSON(routePolyline, {
        pane: "overlayPane",
        style: { color: "#575757", weight: 7 },
      }).addTo(map);
    }
  }, [mapPolyline]);

  return (
    <div id="mapContainer">
        <div className="closeIcon" id="close" onClick={()=> {
      document.getElementById("mapContainer").style.height = "0px";
      document.getElementById("mapContainer").style.overflow = "hidden";
      document.getElementById("close").style.display = "none";


        }}>
            <CloseIcon />
        </div>
      <div id="map"></div>
    </div>
  );
};

export default Map;
