import {Menu, PopoverOrigin} from "@mui/material";
import Button from "@mui/material/Button";
import {ColDef, GridApi} from "ag-grid-community";
import {tableControlStyle} from "../TableControls";
import {Fragment, useRef, useState} from "react";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ColDisplayMenuItem from "./ColDisplayMenuItem";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";


const menuAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const ColFreezeMenu = ({table}) => {
  if(!table.current) {
    return <div>
    </div>
  }
  const gridApi: GridApi = table.current.api;
  let columnApi = table.current.columnApi;
  const columnDefs = columnApi.columnModel.columnDefs as ColDef[]
  const [menuOpened, setMenuOpened] = useState(false);
  const [allVisible, setAllVisibleState] = useState(true);
  const colFreezeBtn = useRef();

  function setAllVisible() {
    setAllVisibleState(true)
  }

  function onColUpdate() {
    var allVisible = true;
    columnDefs.forEach((colDef) => {
      if (colDef.hide) allVisible = false;
    });
    setAllVisibleState(allVisible)
    
    
  }

  let colFreezeItems = columnDefs.map((colDef: ColDef) => (
    <ColDisplayMenuItem override={allVisible} onUpdate={onColUpdate} key={colDef.field} colDef={colDef}
                        gridApi={gridApi} columnDefs={columnDefs}/>
  ));
  let colDisplayItems = <Menu
    open={menuOpened}
    onClose={() => setMenuOpened(false)}
    anchorEl={colFreezeBtn.current}
    anchorOrigin={menuAnchorOrigin}
    container={document.getElementById("fullScreen-wrap")}

  >
    <MenuItem onClick={() => {
      setAllVisible()
    }}>
      <input
        type="checkbox"
        checked={allVisible}
        onChange={() => {
        }}
      >
      </input>
      All
    </MenuItem>
    <Divider orientation="horizontal"/>
    {colFreezeItems}
  </Menu>;
  return <Fragment>
    <Button startIcon={<VisibilityOutlinedIcon/>} endIcon={<ArrowDropDownIcon/>} ref={colFreezeBtn}
            sx={tableControlStyle} onClick={() => setMenuOpened(!menuOpened)}>
      Columns
    </Button>
    {colDisplayItems}
  </Fragment>
};

export default ColFreezeMenu;