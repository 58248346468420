import {useEffect} from "react";
import axios from "axios";

const Logout = () => {
    useEffect(() => {
        (async () => {
            try {
                // eslint-disable-next-line
                const {data} = await axios.post('https://api.albfqngnktbw.net/logout/',
                { refresh_token:localStorage.getItem('refresh_token') } ,
                { headers: {'Content-Type': 'application/json'}},
                { withCredentials: true }
                );
                localStorage.clear();
                axios.defaults.headers.common['Authorization'] = null;
                window.location.href = '/login';
           } catch (e) {
             console.log('logout not working', e)
           }
         })();
    }, []);
    
    return (
       <div></div>
    )
}

export default Logout;