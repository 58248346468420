import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import {
  API_URL_COMPANY,
  API_URL_RATING,
} from "../../configuration/Configuration";
import { menuItemsDict, isPosted } from "../../configuration/Utils";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

var companyState = false;
var selectedCompany = "";

const CustomerData = (props) => {
  const { key } = useLocation();
  useEffect(() => {
    setComp("");
    setCompName("");
    companyState = false;
    getCompanies();
  }, [key]);
  // eslint-disable-next-line
  const [company, setComp] = React.useState("");
  const [companyName, setCompName] = React.useState("");
  // eslint-disable-next-line
  const [ratings, setRatings] = React.useState("");
  // eslint-disable-next-line
  const [options1, setOptions1] = React.useState("");
  // handle selection of company
  const changeCompany = (event) => {
    setComp(event.target.value);
    setCompName(options[event.target.value]);
    companyState = true;
    props.func(event.target.value);
  };

  const [valueRating, setRatingValue] = React.useState(2);

  // retrieve menu items out of endpoint
  const [options, setOptions] = React.useState({});

  const getCompanies = () => {
    (async () => {
      const opt = {};
      const ratings = {};
      const { data } = await axios.get(API_URL_COMPANY);
      data.forEach((value) => {
        opt[value.pk] = value.name;
        if (value.rating !== null) {
          ratings[value.name] = value.rating;
        }
      });
      setOptions(opt);
      setRatings(ratings);
    })();
  };

  const postRating = async () => {
    const tenderData = new FormData();
    // Update the formData object
    tenderData.append("rating", valueRating);
    tenderData.append("name", company);
    await axios
      .post(API_URL_RATING, tenderData)
      .then((res) => console.log("Rating posted", res.data));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container xs={12} sx={{ mt: 5 }}>
        <Typography align="left" sx={{ mt: 0, mb: 1, width: "100%" }}>
          <strong>Select the customer</strong>
        </Typography>
        {/* <Typography sx={{ mt: 2, mb: 1 }}>Company</Typography> */}

        <Select
          value={company}
          label=""
          align="left"
          sx={{ width: "350px", mr: 2 }}
          onChange={changeCompany}
        >
          {menuItemsDict(options)}
        </Select>
      </Grid>
      <Grid align="left" sx={{mt:3, mb: 5}}>
        <Button onClick={props.handleNext} variant="contained" size="medium">
          Next
        </Button>
      </Grid>

      <Grid>
        <Typography align="left" sx={{ mt: 2, mb: 1 }}>
          <strong>Customer rating.</strong>
          <br />{" "}
          <span style={{ fontSize: "12px" }}>
            (e.g. based on latest experience in paymnet reliability, complaint
            management)
          </span>
        </Typography>
        {/* </Grid> */}
        {/* <Grid item xs={2}> */}
        {/* <Typography sx={{ mt: 2, mb: 1 }}>Customer Rating</Typography> */}
        <Grid xs={3} alignItems="center" container>
          <Rating
            name="controlled"
            size="large"
            defaultValue={5}
            value={valueRating}
            onChange={(event, newValue) => {
              setRatingValue(newValue);
            }}
          />
          <Button
            sx={{ ml: 2 }}
            variant="outlined"
            size="small"
            onClick={postRating}
            color="inherit"
          >
            Update Rating
          </Button>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Box>
  );
};
export default CustomerData;
