import Button from "@mui/material/Button";
import {tableControlStyle} from "../TableControls";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {Fragment, useState} from "react";
import React from "react";
import Typography from "@mui/material/Typography";


const FullScreenButton = ({fullScreenHandle}) => {
    const [isFullscreen, setIsFullscreen] = useState(false); // Initial state
    return <Fragment>
        <Button
            onClick={
            async () => {
                await (fullScreenHandle.active ? fullScreenHandle.exit() : fullScreenHandle.enter())
                let nowIsFullScreen = !isFullscreen;

                setIsFullscreen(nowIsFullScreen)
            }
            } startIcon={<FullscreenIcon />} sx={tableControlStyle} >
            <Typography style={{
                textDecoration: isFullscreen ? 'underline' : 'none' ,
                color: isFullscreen ? '#1876D2' : "black"
            }}>Fullscreen</Typography>
        </Button>
    </Fragment>
};

export default FullScreenButton;