import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  API_URL_TENDERS_EXPORT,
  API_URL_TENDERS,
  tenderColumnsDefinitionOverview,
  API_URL_LANES_PRICING,
  API_BASE_URL,
} from "../configuration/Configuration";
import { isPosted, headerColumns } from "../configuration/Utils";

import DownloadFile from "../export/export.xlsx";
import { AgGridReact } from "ag-grid-react";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TableControls from "../integrations/core/table/TableControls";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const Export = () => {
  window.addEventListener("beforeunload", () => {
    setTender([]);
  });
  const { key } = useLocation();
  useEffect(() => {
    if (!downloadStatus) {
      reset();
      getTenderDataOverview();
    }
  }, [key]);

  const getTenderDataOverview = () => {
    (async () => {
      const opt = [];
      const url_api = API_URL_TENDERS;
      const { data } = await axios.get(url_api);
      data.forEach((value) => {
        opt.push(value);
      });
      setTenderData(opt);
    })();
  };

  const [userType, setUser] = React.useState({
    isAdmin: false,
    isRegular: false,
    isStaff: false,
  });
  const [isAuth, setIsAuth] = React.useState(false);
  React.useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true);
      let tempUser = localStorage.getItem("user");

      axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`).then((res) => {
        const isAdmin = res.data.is_admin;
        const isStaff = res.data.is_staff;
        const isRegular = res.data.is_regular;
        const user = tempUser
        setUser({ isAdmin, isStaff, isRegular, user });
      });
    }
  }, [isAuth]);
  // eslint-disable-next-line
  const [export_filename, setExportFilename] = React.useState(undefined);
  const [downloadStatus, setDownloadStatus] = React.useState(false);

  const [tenders, setTender] = React.useState("");
  const [tenderData, setTenderData] = React.useState([]);
  const [tenderData2, setTenderData2] = React.useState([]);

  const loadTender = (mode) => () => {
    (async () => {
      const url_api_file = API_URL_TENDERS_EXPORT +userType.user+"/" + tenders + "/" + mode + "/";

      try {
        const response = await axios.get(url_api_file, {
          responseType: "blob",
        });
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.split("filename=")[1].split("?")[0];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading file:", error);
      }


     // setExportFilename(data);
    })();
    setDownloadStatus(true);
  };

  const download = () => {
    if (typeof export_filename !== "undefined") {
      const link = document.createElement("a");
      link.download = export_filename;
      link.href = DownloadFile;
      link.click();
    }
  };

  const reset = () => {
    setTenderSelectState(false);
    setDownloadStatus(false);
  };

  // grid:
  const gridRef1 = React.useRef();
  const gridRef2 = React.useRef();
  const gridStyle = React.useMemo(() => ({ height: 540, width: "100%" }), []);

  const [tendersSelectionState, setTenderSelectState] = React.useState(false);
  const onTenderSelected = (params) => {
    setTender(params.data.id);
    getTenderData(params.data.id);
    setTenderSelectState(true);
  };

  const [gridAdmin, setGridAdmin] = React.useState();
  const [company, setCompany] = React.useState("");

  const getTenderData = (tender) => {
    (async () => {
      const url_api = API_URL_LANES_PRICING + tender + "/admin";
      const { data } = await axios.get(url_api);
      setTenderData2(data.rows);
      setCompany(data.tender[0].company);
      let laneColumnDefinitionAdmin = await headerColumns(tender, true);
      setGridAdmin(laneColumnDefinitionAdmin);
    })();
  };
  let fullScreenHandle = useFullScreenHandle();
  return (
    <Box sx={{ flexGrow: 1 }}>
      {isAuth && isPosted(tendersSelectionState) && (
        <>
          {(userType.isAdmin || userType.isStaff) && (
            <Grid item xs={1} align="left">
              <Button
                variant="outlined"
                size="medium"
                color="inherit"
                onClick={reset}
              >
                {`← Back`}
              </Button>

              <Grid item xs={12} sx={{ mt: 5 }}>
                <Typography sx={{ mt: 2, mb: 1 }} align="left">
                  {company} tender with ID {tenders} Selected
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
      <FullScreen handle={fullScreenHandle}>
        {isAuth && (
          <>
            {(userType.isAdmin || userType.isStaff) && (
              <Grid
                container
                spacing={2}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={0.5}></Grid>

                {/* New Line */}
                <Grid item xs={0.5}></Grid>
                <Grid item xs={12}>
                  {isPosted(!tendersSelectionState) && (
                    <div style={gridStyle} className="ag-theme-alpine">
                      <TableControls
                        table={gridRef1}
                        fullScreenHandle={fullScreenHandle}
                      />

                      <AgGridReact
                        ref={gridRef1} // Ref for accessing Grid's API
                        rowData={tenderData} // Row Data for Rows
                        columnDefs={tenderColumnsDefinitionOverview} // Column Defs for Columns
                        onRowDoubleClicked={onTenderSelected}
                        pagination={true}
                        paginationAutoPageSize={true}
                      />
                    </div>
                  )}
                  {isPosted(tendersSelectionState) && (
                    <div style={gridStyle} className="ag-theme-alpine">
                      <TableControls
                        table={gridRef2}
                        fullScreenHandle={fullScreenHandle}
                      />

                      <AgGridReact
                        ref={gridRef2} // Ref for accessing Grid's API
                        rowData={tenderData2} // Row Data for Rows
                        columnDefs={gridAdmin} // Column Defs for Columns
                        pagination={true}
                        paginationAutoPageSize={true}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
       
        {isPosted(tendersSelectionState) && (
          <>
            
           {(userType.isAdmin || userType.isStaff) &&( 
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 5 }}
                  justify="flex-end"
                  alignItems="center"
                >

                  <Grid item xs={12}></Grid>

                  <Grid item xs={12}>
                    <Typography align="left">
                      Select a full export or a customer export without internal
                      information.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="left" alignItems={"stretch"}>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={loadTender("full")}
                      color="inherit"
                    >
                      Full Export
                    </Button>
                  </Grid>
                  <Grid item xs={2} align="left" alignItems={"stretch"}>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={loadTender("customer")}
                      color="inherit"
                    >
                      Customer Export
                    </Button>
                  </Grid>
                  <Grid item xs={2} alignItems={"stretch"}>
                    {isPosted(downloadStatus) && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={download}
                      >
                        Download
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={7}></Grid>
                </Grid>
              )}
          </>
        )}
        {!userType.isAdmin && !userType.isStaff && (
          <Grid item xs={12}>
            <Typography>Tender Management only!</Typography>
          </Grid>
        )}
      </FullScreen>
    </Box>
  );
};
export default Export;
