import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FileUploader } from "react-drag-drop-files";
import { fileTypes, API_URL_TENDERS } from "../configuration/Configuration";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import axios from "axios";
import loadFile from '../utils/updateLanesCSV'

const Import = () => {
  const [searchParams] = useSearchParams();
  const tenderId = searchParams.get("id");
  const [tender, setTender] = useState();

  useEffect(() => {
    if (tenderId) {
      axios.get(`${API_URL_TENDERS}${tenderId}`).then((res) => {
        setTender(res.data[0]);
      });
    }
  }, []);

  const handleFileChange = (file) => {
    const result = loadFile(file, tenderId)
    console.log(result)
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item xs={9}>
        <Typography align="left" sx={{ mt: 2, mb: 1 }}>
          <strong>Updating the lane information for tender ID {tender?.id}, company {tender?.company}</strong>
        </Typography>
      </Grid>
      <Grid item={false} xs={10}></Grid>

      <Grid item xs={4}>
        <FileUploader
          style={{ width: "50" }}
          handleChange={handleFileChange}
          name="file"
          types={fileTypes}
        />
      </Grid>
    </Box>
  );
};

export default Import;
